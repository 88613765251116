/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import {
  startPurchase,
  validatePaypalPurchase,
  validateCryptoPurchase,
} from '../../actions/payments';
import PaypalCheckout from '../PaypalCheckout';
import { setToastNotification } from '../../utils/helpers';

const BuyCreditsModal = ({
  isShow,
  setIsShow,
  setQuantity,
  quantity,
  setLoading,
  startPurchase,
  validatePaypalPurchase,
  username,
  settings: { tokens },
  payments: { payment_info },
  isShowPaymentInfo,
  setIsShowPaymentInfo,
}) => {
  const [selectedToken, setSelectedToken] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const handleCryptoPayment = async () => {
    if (quantity % 1000 > 0) {
      return setToastNotification('Credits must be divisible by 1000', 'error');
    }
    setLoadingPayment(true);
    const payload = {
      username,
      payment_method: 'crypto',
      currency: selectedToken,
      items: [{ type: 'credits', quantity }],
    };
    await startPurchase(payload);
    setIsShow(false);
    setIsShowPaymentInfo(true);
    setLoadingPayment(false);
  };
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='400'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Buy Credits</h3>
          </div>
          <div className='modal__body'>
            <p className='my-2 mb-3 text-center'>
              Enter the quantity of CREDITS you are intending to buy, you can
              always buy more at a future time.
              <br />
              <small className='text-warning text-small text-bold my-0'>
                NOTE: 1000 CREDITS = $1.
              </small>
            </p>
            <div>
              <div className='credits__double__input__row'>
                <div className='nfttunz__credits__input__wrapper nfttunz__input__border w-50 m-auto'>
                  <button
                    disabled={quantity > 2000000}
                    onClick={() => {
                      setQuantity(quantity + 1000);
                    }}
                  >
                    <strong>+</strong>
                  </button>
                  <div name='creditUnits' className='credit__units'>
                    <h4 className='m-0' id='button_quantity'>
                      {quantity}
                    </h4>
                  </div>
                  <button
                    disabled={1000 === quantity}
                    onClick={() => {
                      setQuantity(quantity - 1000);
                    }}
                  >
                    <strong>-</strong>
                  </button>
                </div>
                <button
                  className='manual__entry'
                  onClick={() => setManualEntry(!manualEntry)}
                >
                  enter manually
                </button>
                <input
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className={`w-100 ${manualEntry === true ? 'show' : 'hide'}`}
                  type='number'
                  id='quantity'
                  placeholder='How many credits do you want to buy?'
                />
              </div>
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-md-12'>
              <div className='buy__with__crypto__wrapper'>
                <select
                  className='crypto__select w-100'
                  name='category'
                  id='category'
                  onChange={(e) => {
                    setSelectedToken(e.target.value);
                  }}
                  defaultValue='select'
                >
                  <option value='select' disabled>
                    Select Category
                  </option>
                  {tokens?.map((token, index) => (
                    <option key={index} value={token.symbol}>
                      {token.name} ({token.symbol})
                    </option>
                  ))}
                </select>
                <button
                  className='buy__with__crypto__button'
                  onClick={() => handleCryptoPayment()}
                >
                  {loadingPayment ? (
                    <i className='fas fa-spinner'></i>
                  ) : (
                    'With Crypto'
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-md-12'>
              <h4 className='text-center bold my-2'>OR</h4>
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-md-12'>
              {/* TODO: Try to find out why button doesn't show on first render */}
              {(() => {
                return (
                  <PaypalCheckout
                    setIsShow={setIsShow}
                    setLoading={setLoading}
                    username={username}
                    startPurchase={startPurchase}
                    validatePaypalPurchase={validatePaypalPurchase}
                  />
                );
              })()}
            </div>
          </div>
          <button className='modal__text__button' onClick={() => setIsShow()}>
            close
          </button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.users.username,
  settings: state.settings,
  payments: state.payments,
});

export default connect(mapStateToProps, {
  startPurchase,
  validatePaypalPurchase,
  validateCryptoPurchase,
})(BuyCreditsModal);
