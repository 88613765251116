import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import VideoPlayer from "react-video-markers";
import {
  getUserCollectibles,
} from '../../actions/collectibles';
import { getNFTInstances } from '../../actions/nfts';
import Cards from '../../components/cards/Cards';
import UploadLoader from '../../components/UploadLoader';

const Collectibles = ({
  collectibles: { user_collectible, loading },
  getUserCollectibles,
  getNFTInstances,
  nfts,
}) => {



  useEffect(() => {
    getUserCollectibles();
    getNFTInstances({ account: localStorage.getItem('username') });
  }, [getUserCollectibles, getNFTInstances]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header'>
              <h6>Here are your Curator Licenses</h6>
            </div>
          </div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          <div className='arrow__wrapper left__'>
            <i className='fa fa-chevron-left'></i>
          </div>
          <div className='row'>
            {user_collectible === null || loading ? (
              <UploadLoader size='small' />
            ) : (
              <Cards
                songs={user_collectible}
                market={false}
                col={true}
                nfts={nfts}
              />
            )}
          </div>
          <div className='arrow__wrapper right__'>
            <i className='fa fa-chevron-right'></i>
          </div>
        </div>
      </div>
    
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
  nfts: state.nfts.instances,
});
export default connect(mapStateToProps, {
  getUserCollectibles,
  getNFTInstances,
})(Collectibles);
