import axios from 'axios';
import store from '../store';
import {
  GET_BALANCES,
  GET_NFT_DEFINITION,
  GET_NFT_INSTANCES,
  GET_NFT_SELLBOOK,
  GET_NFT_AUCTIONS,
  SET_INSTANCE_LOADING,
} from './types';
import { sidechain_rpc, symbol } from '../utils/constants';
import { setToastNotification } from '../utils/helpers';
import instance from '../utils/axios';
import { validateTransaction } from './transactions';

const call = async (endpoint, request) => {
  const postData = {
    jsonrpc: '2.0',
    id: Date.now(),
    ...request,
  };
  let result = null;
  const query = await axios.post(`${sidechain_rpc}/${endpoint}`, postData, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  result = query.data.result;
  return await result;
};

const blockchain = async (request) => await call('blockchain', request);

const contract = async (request) => await call('contracts', request);

export const getBalances = (account, symbol) => async (dispatch) => {
  try {
    const query = { account };
    if (symbol) {
      query.symbol = symbol;
    }

    const request = {
      method: 'find',
      params: {
        contract: 'tokens',
        table: 'balances',
        query,
      },
    };
    const data = await contract(request);
    dispatch({
      type: GET_BALANCES,
      payload: data,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getNFTDefinition =
  (query = {}) =>
  async (dispatch) => {
    query = { symbol: symbol, ...query };
    const request = {
      method: 'findOne',
      params: {
        contract: 'nft',
        table: 'nfts',
        query,
      },
    };
    const data = await contract(request);
    dispatch({
      type: GET_NFT_DEFINITION,
      payload: data,
    });
    return data;
  };

export const getNFTInstances =
  (query, series, offset = 0, limit = 1000) =>
  async (dispatch) => {
    if (
      series.startsWith('bronze') ||
      series.startsWith('gold') ||
      series.startsWith('silver')
    ) {
      query['properties.series'] = `founder-${series}`;
    }

    const symbol = 'TRUSTEX' || (await store.getState().settings.nft_symbol);

    dispatch({
      type: SET_INSTANCE_LOADING,
      payload: true,
    });
    let data = [];
    const limit = 1000;
    let offset = 0;

    const queryData = {
      method: 'find',
      params: {
        contract: 'nft',
        table: `${symbol}instances`,
        query: query ? query : {},
        offset, // increase offset as we loop through
        limit,
        indexes: [
          {
            index: '_id',
            descending: true,
          },
        ],
      },
    };
    const instances = await contract(queryData);

    let filteredData = instances;


    dispatch({
      type: GET_NFT_INSTANCES,
      payload: filteredData,
    });
    dispatch({
      type: SET_INSTANCE_LOADING,
      payload: false,
    });
    return data;
  };

export const getNFTInstance = (id) => async (dispatch) => {
  const request = {
    method: 'findOne',
    params: {
      contract: 'nft',
      table: `${symbol}instances`,
      query: { _id: id },
    },
  };

  const data = await contract(request);
  return data;
};

export const getNFTSellBook =
  (query, series, offset = 0) =>
  async (dispatch) => {
    let data = [];
    const limit = 1000;
    let results = [];
    let newData = 0;
    let offset = 0;
    try {
      const symbol = 'TUNZ' || (await store.getState().settings.nft_symbol);
      do {
        const request = {
          method: 'find',
          params: {
            contract: 'nftmarket',
            table: `${symbol}sellBook`,
            // disable query coming from Market Collectible to show all including secondary sellers
            query: query ? query : {},
            offset,
            limit,
          },
        };

        data = await contract(request);
        newData = data.length;

        if (data.length > 0) {
          results.push(...data);

          if (data.length < limit) {
            newData = 0;
          }
        }

        offset += 1000;
      } while (newData > 0);
      const internalSellBook = await instance.get(`/nfts?series=${series}`);

      data = results.map((c) => ({
        account: c.account,
        nft_id: Number(c.nftId),
        series: c.grouping.series,
        price: Number(c.price),
        symbol: c.priceSymbol,
        fee: c.fee,
        // Get edition internally
        edition: internalSellBook.data.nfts.find(
          (i) => i.nft_id === Number(c.nftId),
        )?.edition,
      }));
      dispatch({
        type: GET_NFT_SELLBOOK,
        payload: data,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

export const getNFTInterests = (query = {}, offset = 0, limit = 1000) => {
  const symbol = query.symbol || store.getState().settings.nft_symbol;

  const request = {
    method: 'find',
    params: {
      contract: 'nftmarket',
      table: `${symbol}openInterest`,
      query,
      offset,
      limit,
    },
  };

  return contract(request);
};

export const getNFTTradeHistory = (query, offset = 0, limit = 1000) => {
  const symbol = query.symbol || store.getState().settings.nft_symbol;

  const request = {
    method: 'find',
    params: {
      contract: 'nftmarket',
      table: `${symbol}tradesHistory`,
      query,
      offset,
      limit,
    },
  };

  return contract(request);
};

export const getRegisteredPacks = (query = {}) => {
  const request = {
    method: 'find',
    params: {
      contract: 'packmanager',
      table: 'packs',
      query,
    },
  };

  return contract(request);
};

export const getToken = (symbol) => {
  const request = {
    method: 'findOne',
    params: {
      contract: 'tokens',
      table: 'tokens',
      query: { symbol },
    },
  };

  return contract(request);
};

export const getTokens = (query = {}, offset = 0, limit = 1000) => {
  const request = {
    method: 'find',
    params: {
      contract: 'tokens',
      table: 'tokens',
      query,
      offset,
      limit,
    },
  };

  return contract(request);
};

export const getTransaction = (txid) => {
  const request = {
    method: 'getTransactionInfo',
    params: {
      txid,
    },
  };

  return blockchain(request);
};

export const getTypes = (query = {}, offset = 0, limit = 1000) => {
  const request = {
    method: 'find',
    params: {
      contract: 'packmanager',
      table: 'types',
      query,
      offset,
      limit,
    },
  };

  return contract(request);
};

export const getManagedNFTInfo = (symbols) => {
  const query = { nft: symbols };

  if (Array.isArray(symbols)) {
    query.nft = { $in: symbols };
  }

  const request = {
    method: 'find',
    params: {
      contract: 'packmanager',
      table: 'managedNfts',
      query,
    },
  };

  return contract(request);
};

export const transferNFT =
  (nft, username, recipient, memo) => async (dispatch) => {
    const sidechain_id = await store.getState().settings.sidechain_id;
    // const nfts = nft
    //   .filter((c) => c.owner === username && !c.on_market)
    //   .map((c) => c.nft_id.toString());
    let nfts = [];
    nfts.push(nft);
    const json = {
      contractName: 'nft',
      contractAction: 'transfer',
      contractPayload: {
        to: recipient,
        nfts: [{ symbol: symbol, ids: nfts }],
        memo,
      },
    };
    const jsonData = {
      id: sidechain_id,
      key: 'Active',
      data: json,
      message: `Transfer NFT (${symbol})`,
      eventName: 'nft-transfer-successful',
      mutation: 'cart/EMPTY_CART',
    };

    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          await validateTransaction(r.result.id);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
    );
  };
export const airdropNFTKc =
  (nft, username, series, songs) => async (dispatch) => {
    const sidechain_id = await store.getState().settings.sidechain_id;
    const list = [
      { to: 'bait002', ids: ['11', '12'] },
      { to: 'aggroed', ids: ['13', '6', '4'] },
      { to: 'bennierex', ids: ['39'] },
      { to: 'cryptomancer', ids: ['16'] },
    ];
    let nfts = [];
    nfts.push(nft);
    const json = {
      contractName: 'nftairdrops',
      contractAction: 'newAirdrop',
      contractPayload: {
        symbol,
        list,
      },
    };
    const jsonData = {
      id: sidechain_id,
      key: 'Active',
      data: json,
      message: `Airdrop NFT (${symbol})`,
      eventName: 'nft-airdrop-successful',
      mutation: 'cart/EMPTY_CART',
    };

    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          await validateTransaction(r.result.id);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
    );
  };

export const airdropNFT =
  (series, song = '') =>
  async (dispatch) => {
    try {
      const settings = await store.getState().settings;
      const username = await store.getState().users.username;
      const community = await instance.get(
        `/communities/info?series=${series}`,
      );

      const members = community.data.members.filter((m) => m !== username);
      const { data } = await instance.get(
        '/collectibles/airdrop-list?series=' +
          series +
          (song ? '&song=' + song : ''),
      );

      if (data.status !== 'OK') {
        setToastNotification(data, 'error');
        return;
      }

      const nfts = data.nfts.map((d) => d._id);

      let list = [];
      for (let i = 0; i < nfts.length; i++) {
        let obj = {};
        if (members[i] === undefined) break;
        obj.to = members[i];
        obj.ids = [nfts[i].toString()];
        list.push(obj);
      }

      const json = {
        contractName: 'nftairdrops',
        contractAction: 'newAirdrop',
        contractPayload: {
          symbol,
          list,
        },
      };
      const jsonData = {
        id: settings.sidechain_id,
        key: 'Active',
        data: json,
        message: `Airdrop NFT (${symbol})`,
        eventName: 'nft-airdrop-successful',
      };

      window.hive_keychain.requestCustomJson(
        username,
        jsonData.id,
        jsonData.key,
        JSON.stringify(jsonData.data),
        jsonData.message,
        (r) => {
          if (r.success) {
            console.log(r);
            setToastNotification(r.message, 'success');
          }
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  };

export const airdropTokens = (series, song, nfts) => async (dispatch) => {
  try {
    let data;
    if (song)
      data = await instance.get(
        `/collectibles/airdrop?series=${series}&song=${song}`,
      );
    data = await instance.get(`/collectibles/airdrop?series=${series}`);
    if (data.data.status === 'OK')
      return setToastNotification('Airdrop successfull', 'success');
    else if (data.data.status === 'FAILED')
      return setToastNotification('Airdrop failed', 'error');
    else return setToastNotification(data.data, 'error');

    // const username = await store.getState().users.username;
    // const ts = Date.now();

    // window.hive_keychain.requestSignBuffer(
    //   username,
    //   `${username}${ts}`,
    //   'Posting',
    //   async (r) => {
    //     if (r.success) {
    //       const sig = r.result;
    //       await instance.get(`/collectibles/airdrop?series=${series}&sig=${sig}&ts=${ts}`);
    //       setToastNotification('Airdrop successfull', 'success');
    //     } else {
    //       setToastNotification('Login Failed, try again', 'error');
    //     }
    //   },
    // );
  } catch (err) {
    console.error(err.message);
  }
};
export const whaleDrop = (series, song, nfts) => async (dispatch) => {
  try {
    let data;
    if (song)
      data = await instance.get(
        `/collectibles/airdrop/christmas?series=${series}&song=${song}`,
      );
    data = await instance.get(
      `/collectibles/airdrop/christmas?series=${series}`,
    );
    if (data.data.status === 'OK')
      return setToastNotification('Airdrop successfull', 'success');
    else if (data.data.status === 'FAILED')
      return setToastNotification('Airdrop failed', 'error');
    else return setToastNotification(data.data, 'error');

    // const username = await store.getState().users.username;
    // const ts = Date.now();

    // window.hive_keychain.requestSignBuffer(
    //   username,
    //   `${username}${ts}`,
    //   'Posting',
    //   async (r) => {
    //     if (r.success) {
    //       const sig = r.result;
    //       await instance.get(`/collectibles/airdrop?series=${series}&sig=${sig}&ts=${ts}`);
    //       setToastNotification('Airdrop successfull', 'success');
    //     } else {
    //       setToastNotification('Login Failed, try again', 'error');
    //     }
    //   },
    // );
  } catch (err) {
    console.error(err.message);
  }
};

export const getUserNFTs = (username) => {
  const request = {
    method: 'find',
    params: {
      contract: 'nft',
      table: 'nfts',
      query: { owner: username },
    },
  };

  return contract(request);
};

export const getAuctionInstances =
  (series, username, offset = 0, limit = 1000) =>
  async (dispatch) => {
    const symbol = await store.getState().settings.nft_symbol;
    let query = {};
    if (series) query['properties.series'] = series;
    if (username) query.previousAccount = username;
    query.account = 'nftauction';
    const request = {
      method: 'find',
      params: {
        contract: 'nftauction',
        table: 'auctions',
        query: { symbol },
        offset,
        limit,
      },
    };
    const request2 = {
      method: 'find',
      params: {
        contract: 'nft',
        table: `${symbol}instances`,
        query,
        offset,
        limit,
      },
    };

    const data = await contract(request);
    const data2 = await contract(request2);
    console.log(data2);
    let auctions = [];

    await data2?.forEach(function (d) {
      let auction = {};
      let instance = data.find((i) => Number(d._id) === Number(i.nftIds[0]));
      auction = { ...d, ...instance };
      auctions.push(auction);
    });
    console.log(auctions);

    dispatch({
      type: GET_NFT_AUCTIONS,
      payload: auctions,
    });
  };
