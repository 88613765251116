import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  getCuratorsCollectibles,
  getValidatorsCollectibles,
} from '../actions/collectibles';
import { getArtistes } from '../actions/profile';
import { getTopCreators, getHomeStats } from '../actions/statistics';
import Loader from '../components/Loader';
import MetaDecorator from '../components/utils/MetaDecorator';
import SellersCards from '../components/SellersCards';
import Statistics from '../components/Statistics';
import BannerImage from '../components/BannerImage';
import { Hero } from '../components/Hero';
import LayoutLanding from '../components/LayoutLanding';
import { Redirect } from 'react-router-dom';
import Accordion from '../components/Accordion/Accordion';
import { accordionData } from '../utils/data';
import CuratorCards from '../components/cards/CuratorCards';
import { creators } from '../utils/constants';

const Landing = ({
  getCuratorsCollectibles,
  getValidatorsCollectibles,
  getArtistes,
  getTopCreators,
  statistics: { top_creators, home_stats },
  getHomeStats,
  collectibles: { curators_collectibles, validators_collectibles },
  users: { authenticated },
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCuratorsCollectibles();
    getValidatorsCollectibles();
    getArtistes();
    getTopCreators();
    getHomeStats();
    setLoading(false);
  }, [
    getCuratorsCollectibles,
    getValidatorsCollectibles,
    getArtistes,
    getTopCreators,
    getHomeStats,
  ]);
  if (authenticated) {
    return <Redirect to='/home' />;
  }
  return (
    <Fragment>
      <MetaDecorator
        title='Trust Exchange NFT || Home'
        description='Validator and Curators license collections'
        imageUrl='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
      />
      <LayoutLanding>
        <Hero />

        <section className='section__wrapper mt-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='explore__header nfttunz__stats__header text-center'>
                  Curator Licenses
                </h2>
              </div>
            </div>
            <div className='row'>
              {loading ? (
                <Loader />
              ) : curators_collectibles?.length < 1 ? (
                <h4 className='text-center p-5'>
                  No data available for your filter
                </h4>
              ) : (
                <CuratorCards data={curators_collectibles} />
              )}
            </div>
          </div>
        </section>
        {/* Validators */}
        <section className='section__wrapper my-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='nfttunz__stats__header explore__header text-center'>
                  Validator Licenses
                </h2>
              </div>
            </div>
            <div className='row'>
              {validators_collectibles?.length < 1 ? (
                <h4 className='py-5'>Validator Licenses are coming soon!</h4>
              ) : (
                <CuratorCards data={validators_collectibles} />
              )}
            </div>
          </div>
        </section>

        <section className='my-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='nfttunz__stats__header text-center my-5'>
                  Top Validators
                </h2>
              </div>
            </div>
            <div className='animate__scrolling'>
              <SellersCards artistes={top_creators?.slice(0, 8)} />
            </div>
            <div className='animate__scrolling glide__right'>
              <SellersCards artistes={top_creators?.slice(9, 17)} />
            </div>
          </div>
        </section>
        <section className='my-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='nfttunz__stats__header text-center my-5'>
                  Top Curators
                </h2>
              </div>
            </div>
            <div className='animate__scrolling'>
              <SellersCards artistes={creators?.slice(0, 8)} />
            </div>
            <div className='animate__scrolling glide__right'>
              <SellersCards artistes={creators?.slice(9, 17)} />
            </div>
          </div>
        </section>
        <Statistics stats={home_stats} />
        <br />
        <br />
        <section className='nfttunz__faq my-5'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='nfttunz__stats__header text-center'>FAQ</h2>
              <p className='text-center w-75 m-auto'>
                Nonfungible tokens (NFTs) have exploded onto the scene as a way
                for musicians and fans alike to monetize and appreciate their
                art in new ways. To help you understand this craze better, we've
                answered some common questions about what NFTs are and how
                they're being used in music today.
              </p>
            </div>
          </div>
          <div className='accordion'>
            {accordionData.slice(0, 6).map(({ title, content }) => (
              <Accordion title={title} content={content} key={title} />
            ))}
          </div>
        </section>
        <BannerImage />
      </LayoutLanding>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  loading: state.users.loading,
  collectibles: state.collectibles,
  settings: state.settings,
  users: state.users,
  artistes: state.profiles.artistes,
  statistics: state.statistics,
});
export default connect(mapStateToProps, {
  getCuratorsCollectibles,
  getValidatorsCollectibles,
  getArtistes,
  getTopCreators,
  getHomeStats,
})(Landing);
