import React from 'react';
import { Link } from 'react-router-dom';
// import hero from '../assets/images/HERO_IMG.png';

const hero =
  'https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png';
export const Hero = () => {
  return (
    <div className='hero__wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='hero__header__text'>
              <h1>
                Get.Curators <br /> & Validator <br />
                <span>Licenses </span>
              </h1>
              <p>
                For the trusted exchange and verification of mission-critical
                information with partners, suppliers and third parties.
              </p>

              <div className='hero__header__buttons'>
                <Link to='/register'>Get Started</Link>
              </div>
            </div>
            <div className='hero__stats'>
              <div className='hero__stats__item'>
                <div className='hero__stats__item__text'>
                  <h3>
                    500 <span>+</span>
                  </h3>
                  <p>
                    Total Number of <br /> Validators
                  </p>
                </div>
              </div>
              <div className='hero__stats__item'>
                <div className='hero__stats__item__text'>
                  <h3>
                    1000 <span>+</span>
                  </h3>
                  <p>
                    Total Number of <br /> Curators
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='hero__header__image'>
              <img src={hero} width={10} alt='hero' />
              <p>
                <i>
                  Now organizations of all sizes can deploy public and private
                  networks for the exchange of mission-critical information on
                  demand, and at scale.
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
