import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getCuratorsCollectibles,
  getValidatorsCollectibles,
} from '../actions/collectibles';
import { getArtistes } from '../actions/profile';
import { getTopCreators, getHomeStats } from '../actions/statistics';
// import Featured from '../components/Featured';
import Layout from '../components/Layout';
// import Loader from '../components/Loader';
import MetaDecorator from '../components/utils/MetaDecorator';
import SellersCards from '../components/SellersCards';
import Statistics from '../components/Statistics';
import BannerImage from '../components/BannerImage';
import { creators } from '../utils/constants';
import CuratorCards from '../components/cards/CuratorCards';

const Home = ({
  getCuratorsCollectibles,
  getValidatorsCollectibles,
  getArtistes,
  getTopCreators,
  statistics: { top_creators, home_stats },
  collectibles: { curators_collectibles, validators_collectibles },
  getHomeStats,
  settings: { filters },

  users: { authenticated },
}) => {
  useEffect(() => {
    getCuratorsCollectibles();
    getValidatorsCollectibles();
    getArtistes();
    getTopCreators();
    getHomeStats();
  }, [
    getCuratorsCollectibles,
    getValidatorsCollectibles,
    getArtistes,
    getTopCreators,
    getHomeStats,
  ]);
  return (
    <Fragment>
      <MetaDecorator
        title='Trust Exchange || Home'
        description='Buy Validator and Curator Certificates on Trust Exchange'
        imageUrl='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
      />
      <Layout>
        {/* <Featured
          title='Collectible, Scarce, Tokenized Music'
          song={featured[2]}
        /> */}

        <section className='section__wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='explore__header nfttunz__stats__header'>
                  Curator Certificates
                </h2>
              </div>
            </div>
            <div className='row'>
              {curators_collectibles.length < 1 ? (
                <h4 className='text-center p-5'>
                  No data available for your filter
                </h4>
              ) : (
                <CuratorCards data={curators_collectibles} />
              )}
            </div>
          </div>
        </section>
        {/* Validators */}
        <section className='section__wrapper my-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='explore__header nfttunz__stats__header'>
                  Validator Certificates
                </h2>
              </div>
            </div>
            <div className='row'>
              {validators_collectibles?.length < 1 ? (
                <h4 className='py-5'>Validator Licenses are coming soon!</h4>
              ) : (
                <CuratorCards data={validators_collectibles} />
              )}
            </div>
          </div>
        </section>

        <section className='my-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='nfttunz__stats__header text-center my-5'>
                  Top Validators
                </h2>
              </div>
            </div>
            <div className='animate__scrolling'>
              <SellersCards artistes={creators?.slice(0, 8)} />
            </div>
            <div className='animate__scrolling glide__right'>
              <SellersCards artistes={creators?.slice(9, 17)} />
            </div>
          </div>
        </section>
        <Statistics stats={home_stats} />
        <br />
        <br />
        <br />
        <br />
        <BannerImage />
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  loading: state.users.loading,
  collectibles: state.collectibles,
  settings: state.settings,
  users: state.users,
  artistes: state.profiles.artistes,
  statistics: state.statistics,
});
export default connect(mapStateToProps, {
  getCuratorsCollectibles,
  getValidatorsCollectibles,
  getArtistes,
  getTopCreators,
  getHomeStats,
})(Home);
