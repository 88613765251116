import React, { Fragment } from 'react';
import logo from '../assets/images/TrustExchangeStandalone.png';

const Footer = () => {
  return (
    <Fragment>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='footer__wrapper text-center'>
              <div className='footer__logo'>
                <img src={logo} width={30} alt='tunz token' />
                <div className='footer__copyright'>
                  <p>© Trust Exchange, 2023.</p>
                </div>
              </div>
              <div className='footer__social__icons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='http://www.facebook.com/trustexchange'
                >
                  <i className='fab fa-facebook' /> <span>Facebook</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://twitter.com/trustexchane'
                >
                  <i className='fab fa-twitter' /> <span>Twitter</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://discord.gg/mSVe7s3jgB'
                >
                  <i className='fab fa-discord' /> <span>Discord</span>
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='footer__social__links'
                  href='https://www.linkedin.com/company/trustexchange/'
                >
                  <i className='fab fa-linkedin' /> <span>LinkedIn</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
