import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getCollectibles,
  getCollectiblesByType,
  searchCollectibles,
} from '../actions/collectibles';
import Cards from '../components/cards/Cards';
// import Header from '../components/Header';
import Layout from '../components/Layout';
import Tags from '../components/Tags';
import Loader from '../components/Loader';
import UploadLoader from '../components/UploadLoader';
import { tags } from '../utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import MetaDecorator from '../components/utils/MetaDecorator';

const Browse = ({
  getCollectibles,
  collectibles: { collectibles, loading, collectibles_length },
  settings: { filters },
  getCollectiblesByType,
  users: { authenticated },
  searchCollectibles,
  match,
}) => {
  const [page, setPage] = useState(1);
  const [showPlayer, setShowPlayer] = useState(false);
  const handleSearch = (q) => {
    if (q) {
      getCollectiblesByType(q);
      return;
    }
    getCollectibles();
  };
  const fetchMoreData = (page) => {
    const newPage = page + 1;
    setTimeout(() => {
      setPage(newPage);
    }, 1500);
  };
  useEffect(() => {
    getCollectibles(page);
    if (match.params.search) {
      searchCollectibles(match.params.search);
    }
  }, [getCollectibles, page, searchCollectibles, match]);
  return (
    <Fragment>
      <MetaDecorator
        title='NFTTUNZ || Browse'
        description='Share and Create amazing musical collections'
        imageUrl='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-chart.png'
      />
      <Layout>
        {/* <Header title='Browse' button={authenticated ? false : true} /> */}
        {loading ? (
          <Loader />
        ) : (
          <section className='section__wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='section__title text-center'>
                    <h2>Categories</h2>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='tags__main__wrapper'>
                    <Tags tags={tags} handleSearch={handleSearch} />
                  </div>
                </div>
              </div>
              <div className='row'>
                {loading ? (
                  <Loader />
                ) : collectibles.length < 1 ? (
                  <h4 className='text-center p-5'>
                    No data available for your filter
                  </h4>
                ) : (
                  <InfiniteScroll
                    className='row justify-content-center'
                    dataLength={collectibles.length}
                    next={() => fetchMoreData(page)}
                    hasMore={
                      collectibles.length < collectibles_length ? true : false
                    }
                    loader={
                      <h4 className='text-center mt-4'>
                        <UploadLoader size='small' />
                      </h4>
                    }
                    endMessage={
                      <p style={{ textAlign: 'center', marginTop: '30px' }}>
                        <b>Yess! You have seen it all 😁</b>
                      </p>
                    }
                  >
                    {/* <div className='row'> */}
                    <Cards
                      songs={collectibles}
                      market={true}
                      showPlayer={showPlayer}
                      setShowPlayer={setShowPlayer}
                      home={true}
                      loading={loading}
                    />
                    {/* </div> */}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </section>
        )}
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  loading: state.users.loading,
  collectibles: state.collectibles,
  settings: state.settings,
  users: state.users,
});
export default connect(mapStateToProps, {
  getCollectibles,
  getCollectiblesByType,
  searchCollectibles,
})(Browse);
