import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import logo from '../assets/images/TUNZ_Token.png';
import { logout, readNotifications } from '../actions/users';
import { searchCollectibles } from '../actions/collectibles';
import Notifications from './Notifications';

const logo =
  'https://media.discordapp.net/attachments/1068214613801451541/1068222135690084382/trust_exchange_-_logo_-_full_-_flat2x_copy_2.png';

const Navbar = ({
  isShowLogIn,
  isShow,
  auth,
  setIsShowConfirm,
  readNotifications,
  cart,
  toggleTheme,
  theme,
}) => {
  const [search, setSearch] = useState('');

  const toogleAction = async () => {
    if (!auth.authenticated) {
      isShowLogIn(!isShow);
    } else {
      setIsShowConfirm(true);
    }
  };

  return (
    <Fragment>
      <nav className='navbar navbar-expand-lg navbar-toggleable-sm p-0'>
        <div className='nfttunz__navbar'>
          <div className='d-flex unset__flex__mobile'>
            <div className='d-flex mobile__flex__col'>
              <Link className='navbar-brand nfttunz__navbar__brand' to='/'>
                <img src={logo} alt='nav logo' width={200} />
              </Link>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon flex__mobile'>
                  <i className='fas fa-bars'></i>
                </span>
              </button>
            </div>
            <div
              className='collapse navbar-collapse nfttunz__navbar__links__wrapper'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav nfttunz__navbar__links'>
                <li className='nav-item'>
                  <Link className='nav-link' to='/browse'>
                    Validators
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/market'>
                    Curators
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/faq'>
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='nfttunz__navbar__search__main__wrapper hide__mobile'>
            <form className='d-flex'>
              <div className='nfttunz__navbar__search__wrapper'>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className='nfttunz__navbar__search__input'
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                />
                <Link to={`/browse/${search}`} className='btn nfttunz__icon'>
                  <i className='fa fa-search'></i>
                </Link>
              </div>
            </form>
            {/* Auth Group Mint Feature */}
            {auth.authenticated && (
              <Fragment>
                {(auth.whitelisted || auth.admin) && (
                  <div className='nfttunz__notification__wrapper margin__left'>
                    <Link
                      className='nav-link'
                      to='/mint'
                      datatype='tool-tip'
                      title='Mint'
                    >
                      <i className='fa fa-plus'></i>
                    </Link>
                  </div>
                )}
                <div className='dropdown nfttunz__notification__wrapper margin__left'>
                  <button
                    className='dropdown-toggl'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    {auth.notifications.length > 0 && (
                      <span className='notification'>
                        {auth.notifications.length}
                      </span>
                    )}
                    <i className='fas fa-bell'></i>
                  </button>
                  <ul
                    className='dropdown-menu dropdown-menu-lg-end p-0'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <Notifications
                      auth={auth}
                      readNotifications={readNotifications}
                    />
                  </ul>
                </div>
                {/* <div className='nfttunz__notification__wrapper margin__left'>
                  <Link className='dropdown-toggl' to='/checkout'>
                    {cart?.length > 0 && (
                      <span className='notification'>{cart?.length}</span>
                    )}
                    <i className='fas fa-shopping-cart'></i>
                  </Link>
                </div> */}
                <div className='nfttunz__notification__wrapper margin__left'>
                  <Link className='dropdown-toggl' to='/profile/wallet'>
                    <i className='fas fa-wallet'></i>
                  </Link>
                </div>
                <div className='nfttunz__avatar__wrapper margin__left'>
                  <Link to='/profile'>
                    <img
                      src={
                        auth.avatar
                          ? auth.avatar
                          : 'https://cdn.pixabay.com/photo/2016/05/24/22/54/icon-1413583_960_720.png'
                      }
                      alt={auth.username}
                      width={30}
                    />
                  </Link>
                </div>
              </Fragment>
            )}

            <div className='dropdown nfttunz__login__button margin__left d-flex'>
              {!auth.authenticated ? (
                <button className='nav__login__button' onClick={toogleAction}>
                  <span>Login</span>
                </button>
              ) : (
                <button
                  className='dropdown-toggl'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {auth.username} <i className='fa fa-caret-down'></i>
                </button>
              )}

              <ul
                className='dropdown-menu dropdown-menu-lg-end user__profile__dropdown'
                aria-labelledby='dropdownMenuButton1'
              >
                <li>
                  <Link
                    className='dropdown-item drop__down__item'
                    to='/profile'
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item drop__down__item' to='/market'>
                    Gallery
                  </Link>
                </li>
                {auth.admin === true && (
                  <li>
                    <Link
                      className='dropdown-item drop__down__item'
                      to='/admin'
                    >
                      Admin
                    </Link>
                  </li>
                )}
                <li>
                  <button
                    onClick={toogleAction}
                    className='dropdown-item drop__down__item'
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
            {/* {!auth.authenticated && (
              <div className='dropdown nfttunz__register__button margin__left d-flex'>
                <Link className='nav__register__button' to='/register'>
                  <span>Register</span>
                </Link>
              </div>
            )} */}
            {/* <div className='nfttunz__notification__wrapper margin__left d-flex'>
              <button onClick={() => toggleTheme('dark')}>
                {theme === 'dark' ? (
                  <i className='fas fa-sun'></i>
                ) : (
                  <i className='fas fa-moon'></i>
                )}
              </button>
            </div> */}
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.users,
  cart: state.cart.cart,
});

export default connect(mapStateToProps, {
  logout,
  readNotifications,
  searchCollectibles,
})(Navbar);
