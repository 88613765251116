import {
  GET_COLLECTIBLES,
  GET_USER_COLLECTIBLES,
  GET_ARTISTE_COLLECTIBLES,
  GET_COLLECTIBLE,
  SET_MORE_INTERESTS,
  SET_INTERESTS,
  SET_PAGINATION,
  SET_ON_MARKET,
  SET_SERIES,
  SET_COLLECTIONS_NAMES,
  SET_SERIES_STRING,
  SEARCH_COLLECTIBLES,
  GET_PURCHASED_COLLECTIBLES,
  GET_AIRDROP_COLLECTIBLES,
  SET_COLLECTIBLES_LENGTH,
  GET_FEEDS,
  GET_REPORTS,
  GET_CURATORS_COLLECTIBLES,
  GET_VALIDATORS_COLLECTIBLES,
  GET_RECEIVED_COLLECTIBLES,
  COLLECTIBLES_LOADING,
} from './types';
import axios from '../utils/axios';
import store from '../store';
import { arrayChunk, fetchSeries } from '../utils/helpers';
import { sidechain_rpc, symbol } from '../utils/constants';

const call = async (endpoint, request) => {
  const postData = {
    jsonrpc: '2.0',
    id: Date.now(),
    ...request,
  };
  let result = null;
  const query = await axios.post(`${sidechain_rpc}/${endpoint}`, postData, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  result = query.data.result;

  return result;
};

const contract = async (request) => await call('contracts', request);

export const getCollectibles =
  (page, published = true, limit = 20) =>
  async (dispatch) => {
    try {
      const data = await axios.get(`/collectibles/list?published=${published}`);
      // const data = await axios.get(`/collectibles/list?limit=${limit}&page=${page}&published=${published}`);
      dispatch({
        type: GET_COLLECTIBLES,
        payload: data.data.slice(0, page * limit),
      });
      dispatch({
        type: SET_COLLECTIBLES_LENGTH,
        payload: data.data.length,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getMintSeries = () => async (dispatch) => {
  try {
    const username = store.getState().users.username;
    const { data } = await axios.get(`/collectibles/list?username=${username}`);
    const collection_names = data.map((collection) => collection.collection_name);
    dispatch({
      type: SET_COLLECTIONS_NAMES,
      payload: collection_names,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getValidatorsCollectibles =
  (page, published = true, limit = 20) =>
  async (dispatch) => {
    try {
      const data = await axios.get(`/collectibles/list?type=validator`);
      dispatch({
        type: GET_VALIDATORS_COLLECTIBLES,
        payload: data.data.slice(0, 4),
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getCuratorsCollectibles =
  (page, published = true, limit = 20) =>
  async (dispatch) => {
    try {
      const data = await axios.get(`/collectibles/list?type=curator`);
      dispatch({
        type: GET_CURATORS_COLLECTIBLES,
        payload: data.data.slice(0, 4),
      });
    } catch (err) {
      console.error(err.message);
    }
  };

export const getUserCollectibles = () => async (dispatch) => {
  try {
    const data = await axios.get(`/collectibles/list`);
    dispatch({
      type: GET_USER_COLLECTIBLES,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const getArtisteCollectibles = (username) => async (dispatch) => {
  try {
    const data = await axios.get(`/collectibles/list`);
    const artisteData = data.data.filter((d) => d.creator === username);
    dispatch({
      type: GET_ARTISTE_COLLECTIBLES,
      payload: artisteData,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getPurchasedCollectibles =
  (query, offset = 0, limit = 1000) =>
  async (dispatch) => {
    try {
      const username = await store.getState().users.username;
      const NFT_SYMBOL = await store.getState().settings.nft_symbol;
      let data = [];
      const limit = 1000;
      const results = [];
      let newData = 0;
      let offset = 0;
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: true,
      });
      do {
        const queryData = {
          method: 'find',
          params: {
            contract: 'nft',
            table: `${NFT_SYMBOL}instances`,
            query: query ? query : {},
            offset, // increase offset as we loop through
            limit,
            indexes: [
              {
                index: '_id',
                descending: true,
              },
            ],
          },
        };
        data = await contract(queryData);
        newData = data?.length;
        if (data?.length > 0) {
          results.push(...data);

          if (data.length < limit) {
            newData = 0;
          }
        }
        offset += 1000;
      } while (newData > 0);
      const instances = results;
      // Get bought NFTs instances
      const prevOwned = instances.filter(
        (inst) =>
          inst.properties.series.split('_')[0] !== username &&
          (inst.previousAccount === 'nftmarket' || inst.previousAccount === 'nftauction') &&
          inst.account === username,
      );
      // Get NFT instances currently listed but owned
      const onMarket = instances.filter(
        (inst) =>
          inst.account === 'nftmarket' && inst.previousAccount === username,
      );
      // Add NFT instances currently listed and owned
      const purchasedAndOnMarket = prevOwned.concat(onMarket);

      // Extract NFT series only
      const uSeries = Array.from(
        new Set(purchasedAndOnMarket.map((t) => t.properties.series)),
      );
      // Get Collectibles matching series
      const _series = await fetchSeries(uSeries);
      const _seriesWithoutCreator = _series.filter(
        (s) => s.creator !== username,
      );
      const purchaseAndOnMarketWithCount = _seriesWithoutCreator.map((inst) => {
        inst.count = purchasedAndOnMarket.filter(
          (ser) => inst.series === ser.properties.series,
        ).length;
        return inst;
      });
      dispatch({
        type: GET_PURCHASED_COLLECTIBLES,
        payload: purchaseAndOnMarketWithCount,
      });
    } catch (err) {
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: false,
      });
      console.error(err.message);
    }
  };
export const getAirdropCollectibles =
  (query, offset = 0, limit = 1000) =>
  async (dispatch) => {
    try {
      let data = [];
      const limit = 1000;
      let results = [];
      let newData = 0;
      let offset = 0;
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: true,
      });

      const symbol = 'TUNZ' || (await store.getState().settings.nft_symbol);
      do {
        const request = {
          method: 'find',
          params: {
            contract: 'nft',
            table: `${symbol}instances`,
            query,
            offset,
            limit,
          },
        };

        data = await contract(request);

        newData = data.length;

        if (data.length > 0) {
          results.push(...data);

          if (data.length < limit) {
            newData = 0;
          }
        }

        offset += 1000;
      } while (newData > 0);
      const dropped = results.filter(
        (inst) =>
          (inst.properties.series.split('_')[0] !== query.account &&
            inst.previousAccount === 'tunzadmin') ||
          inst.previousAccount === 'nftairdrops',
      );

      const uSeries = Array.from(
        new Set(dropped.map((t) => t.properties.series)),
      );

      const _series = await fetchSeries(uSeries);
      const seriesArray = [];
      for (let i = 0; i < _series.length; i++) {
        const series = _series[i];
        let seriesObject = {};
        seriesObject = {
          ...series,
        };

        seriesObject.count = dropped.filter(
          (d) => d.properties.series === _series.series,
        ).length;

        // TODO: Get who NFT was sent from

        seriesArray.push(seriesObject);
      }
      dispatch({
        type: GET_AIRDROP_COLLECTIBLES,
        payload: seriesArray,
      });
    } catch (err) {
      console.error(err.message);
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: false,
      });
    }
  };
export const getReceivedCollectibles =
  (query, offset = 0, limit = 1000) =>
  async (dispatch) => {
    try {
      let data = [];
      const limit = 1000;
      let results = [];
      let newData = 0;
      let offset = 0;
      query['previousOwnedBy'] = 'u';
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: true,
      });

      const symbol = 'TUNZ' || (await store.getState().settings.nft_symbol);
      do {
        const request = {
          method: 'find',
          params: {
            contract: 'nft',
            table: `${symbol}instances`,
            query,
            offset,
            limit,
          },
        };

        data = await contract(request);

        newData = data.length;

        if (data.length > 0) {
          results.push(...data);

          if (data.length < limit) {
            newData = 0;
          }
        }

        offset += 1000;
      } while (newData > 0);
      const transferred = results.filter(
        (inst) =>
          inst.properties.series.split('_')[0] !== query.account &&
          inst.previousAccount !== ('tunzadmin' || 'nftairdrops'),
      );
      const uSeries = Array.from(
        new Set(transferred.map((t) => t.properties.series)),
      );

      const _series = await fetchSeries(uSeries);
      const seriesArray = [];
      for (let i = 0; i < _series.length; i++) {
        const series = _series[i];
        let seriesObject = {};
        seriesObject = {
          ...series,
        };

        seriesObject.count = transferred.filter(
          (d) => d.properties.series === _series.series,
        ).length;

        seriesArray.push(seriesObject);
      }
      dispatch({
        type: GET_RECEIVED_COLLECTIBLES,
        payload: seriesArray,
      });
    } catch (err) {
      console.error(err.message);
      dispatch({
        type: COLLECTIBLES_LOADING,
        payload: false,
      });
    }
  };

export const getCollectible = (series) => async (dispatch) => {
  try {
    const data = await axios.get(`/collectibles/info?series=${series}`);
    dispatch({
      type: GET_COLLECTIBLE,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};


export const fetchSeriesAction = (seriesNames) => async (dispatch) => {
  let seriesData = {};

  if (Array.isArray(seriesNames)) {
    const promises = [];

    const chunks = arrayChunk(seriesNames, 500);

    for (let i = 0; i < chunks.length; i += 1) {
      promises.push(
        axios.post('collectibles/info', { series: chunks[i].toString() }),
      );
    }

    seriesData = (await Promise.all(promises)).flat(Infinity);
  } else {
    seriesData = await axios.call('collectibles/info', {
      series: seriesNames,
    });
  }

  dispatch({
    type: SET_SERIES,
    payload: seriesData.data,
  });
};

export const fetchOnMarket =
  (query, offset = 0, limit = 1000) =>
  async (dispatch) => {
    try {
      const request = {
        method: 'find',
        params: {
          contract: 'nftmarket',
          table: `${symbol}sellBook`,
          query,
          offset,
          limit,
        },
      };
      let market = await contract(request);

      market = market.map((c) => ({
        account: c.account,
        nft_id: Number(c.nftId),
        series: c.grouping.series,
        price: Number(c.price),
        symbol: c.priceSymbol,
        fee: c.fee,
      }));

      const seriesNames = Array.from(new Set(market.map((c) => c.series)));
      await dispatch(fetchSeriesAction(seriesNames));
      dispatch({ type: SET_ON_MARKET, payload: market });
    } catch (e) {
      console.error(e.message);
    }
  };

export const fetchInterests =
  (infinity = false) =>
  async (dispatch) => {
    try {
      const state = store.getState().market.pagination;
      const {
        page,
        limit,
        sort_by: sortBy,
        has_more: hasMore,
        price_min: priceMin,
        price_max: priceMax,
        rights,
        category,
      } = state;

      if (hasMore) {
        const params = { page: page + 1, limit, sort_by: sortBy };

        if (priceMin) {
          params.price_min = priceMin;
        }
        if (priceMax) {
          params.price_max = priceMax;
        }
        if (rights) {
          params.rights = rights;
        }
        if (category) {
          params.category = category;
        }

        const data = await axios.get('market', params);
        if (data.data.length > 0) {
          if (infinity) {
            dispatch({ type: SET_MORE_INTERESTS, payload: data.data });
          } else {
            dispatch({ type: SET_INTERESTS, payload: data.data });
          }

          dispatch({ type: SET_PAGINATION, payload: { page: page + 1 } });
        } else {
          if (page === 0) {
            dispatch({ type: SET_INTERESTS, payload: data.data });
          }

          dispatch({ type: SET_PAGINATION, payload: { has_more: false } });
        }
      }
    } catch (e) {
      console.error(e.message);
    }
  };

export const searchCollectibles = (query) => async (dispatch) => {
  try {
    const data = await axios.post(`/search`, { q: query });
    dispatch({
      type: SEARCH_COLLECTIBLES,
      payload: data.data,
    });
    dispatch({
      type: SET_COLLECTIBLES_LENGTH,
      payload: data.data.length,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getCollectiblesByType = (query) => async (dispatch) => {
  try {
    const data = await axios.get(`/collectibles/list?type=${query}`);
    dispatch({
      type: SEARCH_COLLECTIBLES,
      payload: data.data,
    });
    dispatch({
      type: SET_COLLECTIBLES_LENGTH,
      payload: data.data.length,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getFeeds =
  (username, offset = 0, limit = 1000) =>
  async (dispatch) => {
    try {
      const feeds = await axios.get(`/users/feed?username=${username}`);
      dispatch({
        type: GET_FEEDS,
        payload: feeds.data,
      });
      dispatch({
        type: SET_COLLECTIBLES_LENGTH,
        payload: feeds.data.length,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
export const getReports = (series) => async (dispatch) => {
  try {
    const reports = await axios.get(`/reports`, { params: { series } });
    dispatch({
      type: GET_REPORTS,
      payload: reports.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getSeriesString = (query) => async (dispatch) => {
  try {
    let data = [];
    const limit = 1000;
    let results = [];
    let newData = 0;
    let offset = 0;

    const symbol = 'TUNZ' || (await store.getState().settings.nft_symbol);
    do {
      const request = {
        method: 'find',
        params: {
          contract: 'nft',
          table: `${symbol}instances`,
          query,
          offset,
          limit,
        },
      };

      data = await contract(request);

      newData = data.length;

      if (data.length > 0) {
        results.push(...data);

        if (data.length < limit) {
          newData = 0;
        }
      }

      offset += 1000;
    } while (newData > 0);

    const uSeries = Array.from(
      new Set(results.map((t) => t.properties.series)),
    );

    dispatch({
      type: SET_SERIES_STRING,
      payload: uSeries,
    });
  } catch (err) {
    console.error(err.message);
  }
};
