import { curators } from '../utils/constants';
import { GET_LICENSE } from './types';


export const getLicense = (series) => async (dispatch) => {
  try {
    const license = curators.find((c) => c.series === series);
    dispatch({
      type: GET_LICENSE,
      payload: license,
    });
  } catch (err) {
    console.error(err.message);
  }
};
