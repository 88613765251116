export const sidechain_rpc = process.env.REACT_APP_RPC;
export const sidechain_id = process.env.REACT_APP_SIDECHAIN_ID;
export const API = process.env.REACT_APP_API;
export const symbol = process.env.REACT_APP_SYMBOL;
export const ACCOUNT_CREATION_FEE = 3;
export const NODES = [
  'https://api.hive.blog',
  'https://api.deathwing.me',
  'https://rpc.ausbit.dev',
  'https://rpc.ecency.com',
];
export const tags = [
  {
    icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Validator NFT',
    value: 'audio',
  },
  {
    icon: 'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Curator NFT',
    value: 'video',
  },
  {
    icon: 'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Enterprise NFT',
    value: 'fan',
  },
  {
    icon: 'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Custom NFT',
    value: 'merchandise',
  },
];
export const songs = [
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/426976/pexels-photo-426976.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Dj',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Party',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Music',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Concert',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Festival',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/801863/pexels-photo-801863.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Nightclub',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/2114365/pexels-photo-2114365.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Club',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
  {
    thumbnail:
      'https://images.pexels.com/photos/3784566/pexels-photo-3784566.jpeg?auto=compress&crop=entropy&cs=tinysrgb&dpr=2&fit=crop&h=50&w=50',
    title: 'Dj Background',
    artiste: 'bait002',
    genre: 'pop',
    duration: '3 mins',
  },
];

export const curators = [
  {
    name: 'Enterprise Curator',
    series: 'enterprise-curator-2023',
    price: 500,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Enterprise.png',
    description:
      'Become a first class contributor with our highest Participant Tier. Our Enterprise Participant Certificate. 25% of the daily token drop will be allocated to Enterprise Certificate holders. You will also receive a 10% lifetime bonus. (Limit of 10,000 Minted)',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'enterprise',
  },
  {
    name: 'Gold Curator',
    series: 'gold-curator-2023',
    price: 100,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Gold.png',
    description:
      'Accelerate your earning potential even further with the Gold Participant Certificate. 12.5% of the daily token drop will be allocated to Gold Certificate holders. You will also receive a 10% lifetime bonus. (10,000 Minted)',
    year: '2022',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'gold',
  },
  {
    name: 'Silver Curator',
    series: 'silver-curator-2023',
    category: 'Electronic (EDM)',
    price: 50,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Silver.png',
    description:
      'Increase your earning potential with the Silver Participant Certificate. 7.5% of the daily token drop will be allocated to Silver Certificate holders. You will also receive a  5% lifetime bonus. (25,000 Minted)',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'silver',
  },
  {
    name: 'Bronze Curator',
    series: 'bronze-curator-2023',
    category: 'Electronic (EDM)',
    rights: 3,
    price: 25,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Bronze.png',
    description:
      'Become a contributing member of Trust Exchange’s Business Information Gateway with the Bronze Participant Certificate. 5% of the daily token drop will be allocated to Bronze Certificate holders. You will also receive a 5% lifetime bonus. (50,000 Minted).',
    year: '2022',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'bronze',
  },
];

export const founder_curators = [
  {
    name: 'Founder Enterprise Curator',
    series: 'founder-enterprise-curator-2023',
    price: 100,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Founder_Enterprise.png',
    description:
      'Become a first class contributor with our highest Participant Tier. Our Enterprise Participant Certificate. 25% of the daily token drop will be allocated to Enterprise Certificate holders. You will also receive a 10% lifetime bonus. (Limit of 10,000 Minted)',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'enterprise',
  },
  {
    name: 'Founder Gold Curator',
    series: 'founder-gold-curator-2023',
    price: 20,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Founder_Gold.png',
    description:
      'Accelerate your earning potential even further with the Gold Participant Certificate. 12.5% of the daily token drop will be allocated to Gold Certificate holders. You will also receive a 10% lifetime bonus. (10,000 Minted)',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'gold',
  },
  {
    name: 'Founder Silver Curator',
    series: 'founder-silver-curator-2023',
    category: 'Electronic (EDM)',
    price: 10,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Founder_Silver.png',
    description:
      'Increase your earning potential with the Silver Participant Certificate. 7.5% of the daily token drop will be allocated to Silver Certificate holders. You will also receive a  5% lifetime bonus. (25,000 Minted)',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'silver',
  },
  {
    name: 'Founder Bronze Curator',
    series: 'founder-bronze-curator-2023',
    category: 'Electronic (EDM)',
    rights: 3,
    price: 5,
    hive_price: 1200,
    editions: 300,
    type: 'curator',
    file: 'https://trustexchange.fra1.cdn.digitaloceanspaces.com/TE_Founder_Bronze.png',
    description:
      'Become a contributing member of Trust Exchange’s Business Information Gateway with the Bronze Participant Certificate. 5% of the daily token drop will be allocated to Bronze Certificate holders. You will also receive a 5% lifetime bonus. (50,000 Minted).',
    year: '2023',
    cid: 'QmSULzA5nfwPTY3H8zHvR8BFnSeh3niqxFJvBdHooEUuhL',
    foil: 'bronze',
  },
];

export const creators = [
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/f3455071d4b21c5481be34f4f684f6d5.png',
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-10-21T19:04:06.119Z',
    updated_at: '2022-10-10T09:03:42.728Z',
    collectible_count: 24,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-21T10:11:16.413Z',
    updated_at: '2023-01-18T15:24:17.877Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/f3455071d4b21c5481be34f4f684f6d5.png',
    instagram: 'https://instagram/phuturemusic',
    location: 'Digital Nowhere',
    portfolio: 'https://phuturemusic.com',
    soundcloud: 'https://soundcloud.com/phuture_music',
    twitter: 'https://twitter.com/phuture_music',
    website: 'https://patreon.com/phuturemusic',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/f3455071d4b21c5481be34f4f684f6d5.png',
    collectible_count: 24,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,

    username: 'VFX Inc',
    created_at: '2021-10-11T12:35:36.016Z',
    updated_at: '2023-02-04T18:30:51.227Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/05b32c300b9629b67fe52a304ae47eea.png',
    bio: 'NFT Tunz Admin ~ Musician and Songwriter',
    full_name: 'Darren Claxton',
    instagram: 'https://www.instagram.com/darrenclaxtonmusic',
    location: 'England',
    portfolio: 'https://linktr.ee/darrenclaxton',
    soundcloud:
      'https://open.spotify.com/artist/7cracag1gBcDLXF8U0zjLl?si=qZ5jXkavTBWd6ilXuttzpQ',
    twitter: 'https://twitter.com/DarrenJClaxton',
    website: 'https://linktr.ee/darrenclaxton',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/05b32c300b9629b67fe52a304ae47eea.png',
    collectible_count: 19,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-12-11T16:15:11.880Z',
    updated_at: '2023-01-11T09:17:46.463Z',
    full_name: 'Billy Korg',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/4e2e9fb1cc6d802dc8c842c88f407d20.png',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/4e2e9fb1cc6d802dc8c842c88f407d20.png',
    bio: 'Billy Korg a.k.a. NuPulse, Electronic Music Producer bred in the Spanish "Raver scene" with the SensinGiJs (SensinDJ + Billy Korg), making live acts just using Roland and Korg hardware. Spent two Years in Ibiza producing and playing live with B&P Blues Progressive (with Nando Pemán -guitar- & Alejandro Pugliano -saxo-), a chill-out and downtempo band making countless shows across the island. Now I use mostly Korg hardware and Logic Audio to make my multi-genre tracks.\n\n# Billy Korg: Music for the Awakening',
    collectible_count: 16,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-15T23:09:14.297Z',
    updated_at: '2023-01-04T02:17:50.880Z',
    bio: 'Making Hybrid Style Music combining multiple genres and sounds simultaneously to create something brand new and unique with every production.',
    full_name: 'WIZARDZ',
    location: 'uk',
    twitter: 'https://twitter.com/WIZARDZ26530460',
    website: 'http://neonempire.42web.io',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/9bb0aee9b266fab4a006e29d90010fbc.png',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/9bb0aee9b266fab4a006e29d90010fbc.png',
    collectible_count: 14,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: false,
    username: 'VFX Inc',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/f3455071d4b21c5481be34f4f684f6d5.png',
    created_at: '2021-10-10T11:42:26.957Z',
    updated_at: '2023-02-21T22:41:27.086Z',
    collectible_count: 12,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-12-01T11:20:17.324Z',
    updated_at: '2022-01-21T01:32:44.643Z',
    bio: 'Podcaster. Streamer & part time hard rock guitar player',
    full_name: 'Unkle Bonehead',
    location: 'Web 3.0',
    soundcloud: 'https://soundcloud.com/unkle-bonehead',
    twitter: 'https://twitter.com/3speakvidshare',
    website: 'https://unklebonehead.xyz',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/36f03831450a9e74c3852b9d4cc1ad60.png',
    collectible_count: 10,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2022-01-13T22:13:55.974Z',
    updated_at: '2022-09-28T19:47:00.047Z',
    bio: 'PA, USA',
    full_name: 'Tom',
    website: 'https://peakd.com/@inthenow',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/398ff20ef8365cebae3273a89e648614.png',
    location: 'Pennsylvania',
    soundcloud: 'https://soundcloud.com/twystedone',
    twitter: 'https://twitter.com/TheTwystedOne',
    collectible_count: 9,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-04T16:50:07.276Z',
    updated_at: '2023-02-24T10:23:38.757Z',
    twitter: 'https://twitter.com/Stickupmusic',
    full_name: 'Stick Up Boys',
    location: 'Brighton',
    instagram: 'https://www.instagram.com/stickupboysuk/',
    website: 'https://www.stickupboys.co.uk/',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/2dae1b824ef80f5f72161b2a6f3f260c.png',
    bio: 'Legendary music makers and gif makers straight outta Brighton UK',
    collectible_count: 8,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: false,
    username: 'VFX Inc',
    email: 'mattyjrocks@gmail.com',
    encrypted_pass:
      'U2FsdGVkX18nv8XOniKrr3nTo/UjgIkAKc124XPws/WtAXGgjOzZkbPDF3K69TY2EFaQOA//2hr5KiSSBpZsXICx7TeiihoEahk3MXA1hkI=',
    encrypted_active:
      'U2FsdGVkX19M7ofQg39OIEYPwTa8U7fy4bLauutG9gnw2LnyArYWgfCSDopXJBSBpbnurXrQ7pmDmdNr0T+Yz/DSJ5n+HT1ok6FDUy8QL/0=',
    created_at: '2022-05-24T10:06:42.212Z',
    updated_at: '2022-07-16T16:45:53.330Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/cf95725cd66f31c51e69c10ad5ef4874.png',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/cf95725cd66f31c51e69c10ad5ef4874.png',
    collectible_count: 7,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-11T21:33:10.868Z',
    updated_at: '2022-06-13T00:43:52.395Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a5a612dd64c1e527e2ff173f71cd4157.png',
    collectible_count: 6,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: false,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2022-03-10T17:16:14.364Z',
    updated_at: '2023-01-02T12:41:03.145Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/12c6671619bcffce16ccfe11622a0fff.png',
    bio: 'Singer songwriter , music producer and collaborator',
    full_name: 'wink',
    location: 'London',
    soundcloud: 'https://soundcloud.com/winkandwoo',
    twitter: 'https://twitter.com/WINKANDW00?lang=en-gb',
    website: 'https://hive.blog/@winkandwoo',
    cover_image:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/cover/12c6671619bcffce16ccfe11622a0fff.png',
    collectible_count: 6,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/12c6671619bcffce16ccfe11622a0fff.png',
    created_at: '2021-10-27T12:35:53.654Z',
    updated_at: '2021-12-07T11:45:54.684Z',
    collectible_count: 5,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-03T16:34:02.521Z',
    updated_at: '2022-10-23T05:55:17.449Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a7d74c4418e7c5c89dd5b84f4b18ee23.png',
    bio: 'Music Producer, tribe owner, & streamer!  FREEDOM TO THE BEATZ!!',
    full_name: 'POLAR!',
    instagram: 'https://www.instagram.com/polarmaestro/',
    location: 'Augusta GA, USA',
    portfolio: 'https://peakd.com/@beatzchain',
    soundcloud: 'https://soundcloud.com/user-937829779-87064922',
    twitter: 'https://twitter.com/Polar_Maestro',
    website: 'https://www.beatzchain.com/created',
    collectible_count: 5,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-03T16:34:02.521Z',
    updated_at: '2022-10-23T05:55:17.449Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a7d74c4418e7c5c89dd5b84f4b18ee23.png',
    bio: 'Music Producer, tribe owner, & streamer!  FREEDOM TO THE BEATZ!!',
    full_name: 'POLAR!',
    instagram: 'https://www.instagram.com/polarmaestro/',
    location: 'Augusta GA, USA',
    portfolio: 'https://peakd.com/@beatzchain',
    soundcloud: 'https://soundcloud.com/user-937829779-87064922',
    twitter: 'https://twitter.com/Polar_Maestro',
    website: 'https://www.beatzchain.com/created',
    collectible_count: 5,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-03T16:34:02.521Z',
    updated_at: '2022-10-23T05:55:17.449Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a7d74c4418e7c5c89dd5b84f4b18ee23.png',
    bio: 'Music Producer, tribe owner, & streamer!  FREEDOM TO THE BEATZ!!',
    full_name: 'POLAR!',
    instagram: 'https://www.instagram.com/polarmaestro/',
    location: 'Augusta GA, USA',
    portfolio: 'https://peakd.com/@beatzchain',
    soundcloud: 'https://soundcloud.com/user-937829779-87064922',
    twitter: 'https://twitter.com/Polar_Maestro',
    website: 'https://www.beatzchain.com/created',
    collectible_count: 5,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-03T16:34:02.521Z',
    updated_at: '2022-10-23T05:55:17.449Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a7d74c4418e7c5c89dd5b84f4b18ee23.png',
    bio: 'Music Producer, tribe owner, & streamer!  FREEDOM TO THE BEATZ!!',
    full_name: 'POLAR!',
    instagram: 'https://www.instagram.com/polarmaestro/',
    location: 'Augusta GA, USA',
    portfolio: 'https://peakd.com/@beatzchain',
    soundcloud: 'https://soundcloud.com/user-937829779-87064922',
    twitter: 'https://twitter.com/Polar_Maestro',
    website: 'https://www.beatzchain.com/created',
    collectible_count: 5,
  },
  {
    banned: false,
    whitelisted: true,
    artiste: true,
    application_pending: false,
    artiste_pending: false,
    whitelist_applied: true,
    artiste_applied: true,
    username: 'VFX Inc',
    created_at: '2021-11-03T16:34:02.521Z',
    updated_at: '2022-10-23T05:55:17.449Z',
    avatar:
      'https://nfttunz-cdn.fra1.digitaloceanspaces.com/avatars/a7d74c4418e7c5c89dd5b84f4b18ee23.png',
    bio: 'Music Producer, tribe owner, & streamer!  FREEDOM TO THE BEATZ!!',
    full_name: 'POLAR!',
    instagram: 'https://www.instagram.com/polarmaestro/',
    location: 'Augusta GA, USA',
    portfolio: 'https://peakd.com/@beatzchain',
    soundcloud: 'https://soundcloud.com/user-937829779-87064922',
    twitter: 'https://twitter.com/Polar_Maestro',
    website: 'https://www.beatzchain.com/created',
    collectible_count: 5,
  },
];
 