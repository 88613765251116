/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import { createToken } from '../actions/token';
import { maxPossibleEditions, setToastNotification } from '../utils/helpers';
import { getBalances } from '../actions/nfts';
import Loader from '../components/Loader';
import { getMintSeries } from '../actions/collectibles';
import { curators, founder_curators } from '../utils/constants';

const MintToken = ({
  createToken,
  settings: { currency, token_issuance_base_fee, token_issuance_fee },
  username,
  getBalances,
  getMintSeries,
  balances,
  history,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    year: '',
    description: '',
    editions: '',
    contentCategory: '',
    foil: '',
  });
  const foilRef = useRef(null);
  const [agreed, setAgreed] = useState(false);
  // const [agreements, setAgreements] = useState([]);
  const [foil, setFoil] = useState(false);
  const [active, setActive] = useState('');
  const [cid, setCid] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [mintLoading, setMintLoading] = useState(false);

  const { name, year, description, rights, editions, contentCategory } =
    formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name === '') {
      setToastNotification('Name cannot be empty', 'error');
      return;
    }

    if (editions === '') {
      setToastNotification('Please enter the number of editions', 'error');
      return;
    }
    if (foil === '') {
      setToastNotification('Please select a foil', 'error');
      return;
    }
    if (contentCategory === '') {
      setToastNotification(
        'Please choose either (Validator, Curator)',
        'error',
      );
      return;
    }
    if (fileUrl === '') {
      setToastNotification('Please choose an image', 'error');
      return;
    }
    if (description === '') {
      setToastNotification('Please description cannot be empty', 'error');
      return;
    }
    // if (agreements.length < 2) {
    //   setToastNotification('You must agree to all all terms', 'error');
    //   return;
    // }
    if (!agreed) {
      setToastNotification('You must agree to all the terms', 'error');
      return;
    }

    const payload = {
      action: 'mint',
      name: name,
      editions: editions,
      type: contentCategory,
      file: fileUrl,
      foil: foil,
      year: year,
      description: description,
      cid: cid,
    };

    console.log({ payload });

    // Do something keychain to broadcast transactions
    setMintLoading(true);
    createToken(payload, history);
    return;
  };
  useEffect(() => {
    getBalances(username, currency);
    getMintSeries();
  }, [getBalances, currency, username, getMintSeries, mintLoading]);
  return (
    <Fragment>
      <Layout>
        {mintLoading ? (
          <Loader />
        ) : (
          <section className='section__wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='info__text__wrapper'>
                    <p className='info__text text-center'>
                      Your current balance is{' '}
                      {balances.map((bal, index) => {
                        return balances.length > 0
                          ? parseFloat(balances[0].balance).toFixed(2)
                          : null;
                      })}{' '}
                      {currency}, which is enough to mint up to{' '}
                      {maxPossibleEditions(
                        balances.length > 0 && balances[0].balance,
                        token_issuance_base_fee,
                        token_issuance_fee,
                      ) < 0
                        ? 0
                        : maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          )}{' '}
                      editions. <br />
                      The first edition costs {token_issuance_base_fee}{' '}
                      {currency} and each additional costs {token_issuance_fee}{' '}
                      {currency}. Please check your balance now and gather some{' '}
                      {currency} on your wallet if you plan to issue more than{' '}
                      {maxPossibleEditions(
                        balances.length > 0 && balances[0].balance,
                        token_issuance_base_fee,
                        token_issuance_fee,
                      ) < 0
                        ? 0
                        : maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          )}{' '}
                      tokens.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                  <div className='mint__form__wrapper'>
                    <form className='mint__form' onSubmit={(e) => onSubmit(e)}>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                          <select
                            className='nfttunz__select w-100 text-center'
                            name='contentCategory'
                            id='contentCategory'
                            onChange={onChange}
                            defaultValue='Choose'
                          >
                            <option
                              value='Choose'
                              className='text-center'
                              disabled
                            >
                              Choose Type
                            </option>
                            <option value='founder_curator'>
                              Founder Curator License
                            </option>
                            <option value='curator'>Curator License</option>
                            <option value='validator'>Validator License</option>
                          </select>
                        </div>
                      </div>
                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <input
                            disabled={
                              contentCategory === 'curator' ||
                              contentCategory === 'founder_curator'
                                ? true
                                : false
                            }
                            value={name}
                            name='name'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='search'
                            placeholder='Title*'
                            aria-label='Search'
                          />
                        </div>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                          <input
                            disabled={
                              contentCategory === 'curator' ||
                              contentCategory === 'founder_curator'
                                ? true
                                : false
                            }
                            value={year}
                            name='year'
                            onChange={onChange}
                            className='nfttunz__input'
                            type='text'
                            placeholder='Year*'
                            aria-label='year'
                          />
                        </div>
                      </div>
                      {contentCategory === 'curator' && (
                        <div className='double__input__row'>
                          <div className='nfttunz__input__wrapper nfttunz__input__border d-flex w-100 p-3'>
                            {curators.map((curator, index) => {
                              const { name, file, foil, description, year } =
                                curator;
                              return (
                                <div
                                  onClick={() => {
                                    setActive(foil);
                                    setFoil(foil);
                                    foilRef.current.value = foil;
                                    setFormData({
                                      ...formData,
                                      name,
                                      file,
                                      description,
                                      year,
                                    });
                                  }}
                                  key={index}
                                  className={`trex__mint__type__button ${
                                    active === foil ? 'active' : ''
                                  }`}
                                >
                                  <div
                                    className={`curator__card__wrapper ${foil} text-center`}
                                  >
                                    <img src={file} alt='' width='50' />
                                    <h1
                                      className='text-medium'
                                      style={{ fontSize: '14px' }}
                                    >
                                      {name}
                                    </h1>
                                    <p>Choose {foil}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {contentCategory === 'founder_curator' && (
                        <div className='double__input__row'>
                          <div className='nfttunz__input__wrapper nfttunz__input__border d-flex w-100 p-3'>
                            {founder_curators.map((curator, index) => {
                              const { name, file, foil, description, year } =
                                curator;
                              return (
                                <div
                                  onClick={() => {
                                    setFileUrl(file);
                                    setActive(foil);
                                    setFoil(foil);
                                    foilRef.current.value = foil;
                                    setFormData({
                                      ...formData,
                                      name,
                                      file,
                                      description,
                                      year,
                                    });
                                  }}
                                  key={index}
                                  className={`trex__mint__type__button ${
                                    active === foil ? 'active' : ''
                                  }`}
                                >
                                  <div
                                    className={`curator__card__wrapper ${foil} ${
                                      foil.split('_')[1]
                                    } text-center`}
                                  >
                                    <img src={file} alt='' width='50' />
                                    <h1
                                      className='text-medium'
                                      style={{ fontSize: '12px' }}
                                    >
                                      {name}
                                    </h1>
                                    {/* <p>Choose {foil}</p> */}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border w-100 d-flex'>
                          <textarea
                            value={description}
                            name='description'
                            onChange={onChange}
                            className='nfttunz__textarea w-100'
                            type='search'
                            placeholder='Collection Description*'
                            aria-label='Search'
                            rows={6}
                          ></textarea>
                        </div>
                      </div>

                      <>
                        <div className='double__input__row'>
                          <div className='nfttunz__input__wrapper nfttunz__input__border w-50 d-flex'>
                            <select
                              disabled={
                                contentCategory === 'curator' ||
                                contentCategory === 'founder_curator'
                                  ? true
                                  : false
                              }
                              className='nfttunz__select w-100'
                              name='foil'
                              id='foil'
                              onChange={(e) => setFoil(e.target.value)}
                              defaultValue={foil}
                              ref={foilRef}
                            >
                              <option value={foil} disabled>
                                Foil?
                              </option>
                              <option value={'enterprise'}>Enterprise</option>
                              <option value={'gold'}>Gold</option>
                              <option value={'silver'}>Silver</option>
                              <option value={'bronze'}>Bronze</option>
                              <option value={'enterprise'}>
                                Founder Enterprise
                              </option>
                              <option value={'gold'}>Founder Gold</option>
                              <option value={'silver'}>Founder Silver</option>
                              <option value={'bronze'}>Founder Bronze</option>
                            </select>
                          </div>
                          <div className='nfttunz__input__wrapper nfttunz__input__border w-50'>
                            <input
                              value={editions}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  editions: e.target.value,
                                })
                              }
                              className='nfttunz__input'
                              type='number'
                              placeholder='Number of editions*'
                              aria-label='editions'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div
                              style={{
                                fontSize: '8px',
                                marginLeft: '5px',
                                display: 'block',
                              }}
                            >
                              <small
                                className={`info__text text-small__text d-block ml__auto margin__five text-muted`}
                                style={{
                                  fontSize: '8px',
                                  marginLeft: '10px',
                                  display: 'block',
                                }}
                              >
                                Choose the foil for this licence.
                              </small>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div
                              className='double__input__wrapper d-flex justify-content-end'
                              style={{
                                marginLeft: 'auto',
                                paddingRight: '5px',
                                fontSize: '10px',
                                color: 'red',
                                marginBottom: '5px',
                              }}
                            >
                              {maxPossibleEditions(
                                balances.length > 0 && balances[0].balance,
                                token_issuance_base_fee,
                                token_issuance_fee,
                              ) > 0 && (
                                <span className='info__text text-muted text-small__text'>
                                  {' '}
                                  You can mint up to{' '}
                                  {maxPossibleEditions(
                                    balances.length > 0 && balances[0].balance,
                                    token_issuance_base_fee,
                                    token_issuance_fee,
                                  )}{' '}
                                  editions
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>

                      {/* Agreements */}

                      <div className='double__input__row'>
                        <div className='nfttunz__input__wrapper nfttunz__input__border nfttunz__checkbox__wrapper info__text__wrapper w-100'>
                          <input
                            value={agreed}
                            name='agreed'
                            id='agreed'
                            onChange={(e) => setAgreed(!agreed)}
                            className='nfttunz__checkbox'
                            type='checkbox'
                            aria-label='Search'
                          />
                          <label
                            className='checkbox__label small__text'
                            htmlFor='avatar'
                          >
                            I have verified that the information above are true
                            and authentic.
                          </label>
                        </div>
                      </div>
                      {/* TODO: Prgrammatically calculate editions and fee from state */}
                      <strong className='margin__five'>
                        Total Issuance fee for {editions} edition(s):{' '}
                        {token_issuance_base_fee * editions} {currency}
                      </strong>
                      <br />
                      <br />
                      <input
                        data-toggle='tooltip'
                        data-placement='top'
                        title={
                          maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          ) < 0
                            ? 'You do not have sufficient balance'
                            : 'Mint NFTs'
                        }
                        type='submit'
                        className='trex__buy__button'
                        value='Mint Tokens'
                        disabled={
                          maxPossibleEditions(
                            balances.length > 0 && balances[0].balance,
                            token_issuance_base_fee,
                            token_issuance_fee,
                          ) < 0
                            ? true
                            : false
                        }
                      />
                    </form>
                  </div>
                </div>
                <div className='col-md-2'></div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  token: state.token,
  settings: state.settings,
  username: state.users.username,
  artiste: state.users.artiste,
  balances: state.nfts.balances,
  collection_names: state.collectibles.collection_names,
});
export default connect(mapStateToProps, {
  createToken,
  getBalances,
  getMintSeries,
})(MintToken);
