import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const CuratorCard = ({ curator }) => {
  const { name, file, foil, description, series } = curator;
  return (
    <Fragment>
      <div className={`curator__card__wrapper ${foil}`}>
        <img src={file} alt='' width='150' />
        <h1 className='text-medium'>{name}</h1>
        <p className='mb-2'>
          {description?.length > 140
            ? description.substring(0, 140) + '...'
            : description}
        </p>
        <button className='trex__buy__button'>
          <Link to={`collectible/${series}`}>Get {foil}</Link>
        </button>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  usd_price: state.market.usd_price,
});
export default connect(mapStateToProps, {})(CuratorCard);
