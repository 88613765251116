export const accordionData = [
  {
    title: 'What is an NFT?',
    content: `There are more ways to purchase and collect music than ever before, and some of them are virtual!
    You may be wondering if it's possible to collect music in the same way you would collect other types of art. The answer is yes! While the world of NFTs is still young, there are many exciting ways to buy and collect music.`,
  },
  {
    title: 'How are they being used?',
    content: `NFTs can be used to buy and sell licenses and related merchandise, and anything else you can think of.`,
  },
  {
    title: 'Will they really be around forever?',
    content: `NFTs are a new technology that is still being developed. As such, there’s no way to know how they will develop over time.`,
  },
  {
    title: 'Why would you collect NFTs?',
    content: `Collecting Trust Exhange NFTs is a great way to show off your musical taste and knowledge. You can use them to show the world what you think are the best songs, albums, artists, etc. Support your favorite artists. Supporting artists by buying their NFTs is another way to help them out and make sure they get paid for what they do!`,
  },
  {
    title: 'How do I get started?',
    content: `To begin creating and collecting you will need a Hive account and Hive Keychain. Hive Keychain is a browser extension that securely encrypts your keys and allows you to safely and easily authorize transactions on the platform.

    Get a free account on the GET STARTED tab
    Install the Hive Keychain browser extension: <a href='https://hive-keychain.com/' rel='noopener' target='_blank'>https://hive-keychain.com/</a>`,
  },
  {
    title: 'What is Hive-Engine and why do we need it?',
    content: `Hive-Engine is a smart contract platform built on the Hive Blockchain. One of the smart contracts on Hive-Engine is the NFT contract that NFT Showroom uses to operate the digital marketplace.`,
  },
  {
    title: 'What is SWAP.HIVE?',
    content: `The Hive blockchain uses the HIVE cryptocurrency as the medium of exchange. Hive-Engine utilizes a HIVE backed token called “SWAP.HIVE” to execute all the smart contracts. To interact with NFT Showroom users are required to convert Hive to SWAP.HIVE. You can do this in your wallet on nftshowroom.com by clicking the deposit button and entering the desired amount. Simply sign the transaction with keychain and your Hive will be deposited as SWAP.HIVE.

        There is a 1% fee when converting between HIVE and SWAP.HIVE.
        `,
  },
  {
    title: 'Where are my creations stored?',
    content: `All images uploaded to Trust Exchange are stored on IPFS (InterPlanetary File System) and also backed up on an image server.`,
  },
  {
    title: 'What are HIVE and Hive Power?',
    content: `HIVE is a cryptocurrency that powers the Hive blockchain.

      On Hive you can own liquid HIVE or choose to stake (freeze it) as HIVE Power (HP). HP grants you voting rights on the platform as well as resource credits. Resource credits are consumed when you transact on the blockchain (e.g. tokenizing art NFTs or blogging on DAPPs like peakd.com) These credits regenerate. We recommend you have at least 5 HP staked to be able to smoothly transact. For more information about the Hive blockchain such as wallets, dapps, exchanges and more please visit <a href='https://hive.io/' rel='noopener' target='_blank'>https://hive.io/</a>`,
  },
  {
    title: 'What is Keychain?',
    content: `Hive Keychain is a browser extension that works with Chrome, Brave, and Mozilla to encrypt your keys within a browser. By using Keychain you can prevent your keys from ever being shared with a malicious website.`,
  },
  {
    title: 'Why is the sale price in SWAP.HIVE?',
    content: `The smart contract platfrom Hive-Engine, which is the backend of the NFT Showroom website, requires that all contracts be settled in SWAP.HIVE.`,
  },
  {
    title: 'Can I get a refund?',
    content: `Blockchain transactions are irreversible, so all sales are final.`,
  },
  {
    title: 'Contact and support',
    content: `For all support needs please contact us in discord.`,
  },
];


export const hiveQuestions = [

]